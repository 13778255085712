<template>
  <div>
    <SEARCH :searchChange='onCallbackSearch' :list="searchArr"></SEARCH>
    <TABS :tabChange='onCallbackTab' :list="tabsArr"></TABS>
    <TABLE  :selectChange='onCallbackSelect' :buttonChange='onCallbackButton' :list="tableArr" :data="dataArr"></TABLE>
    <PAGE  :pageChange='onCallbackPage' :limitChange='onCallbackLimit' :count="count"></PAGE>

            <!-- <SEARCH :searchChange='onCallbackSearch' :list="searchArr"></SEARCH>
    <TABS :tabChange='onCallbackTab' :list="tabsArr"></TABS>
    <TABLE :selectChange='onCallbackSelect' :buttonChange='onCallbackButton' :list="tableArr" :data="dataArr"></TABLE>
    <PAGE :pageChange='onCallbackPage' :limitChange='onCallbackLimit' :count="count"></PAGE> -->
  </div>
</template>

<script>
import SEARCH from '@/components/search' // 顶部搜索
import TABS from '@/components/tabs' // 横向 button
import TABLE from '@/components/table' // 列表
import PAGE from '@/components/page' // 分页
import { getWorkTicket, delWorkTicket } from '@/api/project'
export default {
  name: 'WorkTicket',
  components: {
    SEARCH,
    TABS,
    TABLE,
    PAGE,
  },
  data() {
    return {
      searchArr: [
        {
          name: '姓名',
          type: 'input',
          options: [],
          key: 'name',
        },
        {
          name: '所属单位',
          type: 'picker',
          options: [],
          key: 'projectUnit',
        },
        {
          name: '创建时间',
          type: 'date',
          key: 'createTime',
        },
      ], // 搜索列表
      tabsArr: [
        {
          type: 'primary',
          name: '新增工作票',
          func: '',
          isLoading: false,
        },
        {
          type: 'danger',
          name: '批量删除',
          func: 'removeWork',
          isLoading: false,
        },
      ], // 标签列表
      tableArr: [
        {
          prop: 'serial',
          label: '编号',
          width: '150',
        },
        {
          prop: 'nickname',
          label: '姓名',
          width: '140',
        },
        {
          prop: 'unit.unit',
          label: '单位',
          width: '140',
        },
        {
          prop: 'unit.group',
          label: '分组',
          width: '140',
        },
        {
          prop: 'remarks',
          label: '备注',
          width: '180',
        },
        {
          prop: '',
          label: '工作票照片',
          width: '300',
          type: 'img',
        },
        {
          prop: 'create_at',
          label: '创建时间',
          width: '120',
        },
        {
          prop: '',
          label: '操作',
          width: '',
          type: 'but',
        },
      ], // 表头列表
      dataArr: [], // 数据列表
      butnArr: [
        {
          type: '',
          name: '编辑',
          func: '',
        },
        {
          type: 'danger',
          name: '删除',
          func: 'removeWork',
        },
      ], // 数据-操作列表
      count: 0, // 总页数
      formData: {
        page: 1,
        limit: 10,
        nickname: '',
        unit_id: '',
        start_at: '',
        end_at: '',
      },
      ids: '',
    }
  },
  created() {},
  mounted() {
    this._loadData()
  },
  methods: {
    _loadData: function () {
      this.formData.unit_id = this.$store.getters.USER_ID
      getWorkTicket(this.formData).then((res) => {
        console.log(res, '这是工作票管理')
        const { result } = res

        this.count = result.count
        result.data.forEach(item => {
          item.buttons = this.butnArr
          item.imgs = item.files
        })
        this.dataArr = result.data
      })
    },

    // 删除工作票记录
    removeWork: function(key, index) {
      if (!this.ids) {
        this.$toast('请选择要删除的工作票')
        this[key][index].isLoading = false
        return false
      }
      delWorkTicket(this.ids).then(res => {
        this.$toast(res.message)
        this._loadData()
        key && index && (this[key][index].isLoading = false)
      })
    },

    // 收到搜索回调
    onCallbackSearch: function(data) {
      const { name, projectUnit, createTime } = data
      this.formData.start_at = createTime[0]
      this.formData.end_at = createTime[1]
      this.formData.unit_id = projectUnit
      this.formData.nickname = name
      this._loadData()
    },
    // 收到 tab 回调
    onCallbackTab: function({ item, index }) {
      this.tabsArr[index].isLoading = true
      this[item.func]('tabsArr', index)
    },
    // 收到 选择 回调
    onCallbackSelect: function(ids) {
      this.ids = ids
    },
    // 收到 table内方法 回调
    onCallbackButton: function(item, func) {
      this.ids = item.id
      this[func]()
    },

    // 收到改变页码回调
    onCallbackPage: function(page) {
      this.formData.page = page
      this._loadData()
    },
    // 收到改变一页数量回调
    onCallbackLimit: function(limit) {
      this.formData.limit = limit
      this._loadData()
    },
  },
}
</script>

<style lang="stylus" scoped></style>
